.RaLogin-avatar {
  display:none !important;
}
.GridGroupTitle {
  background-color: #189eaf;
  padding: 8px 18px;
  color:#FFF;
}
.RaRichTextInput-editorContent div {
  min-height: 80px !important; 
}

.css-dsuxgy-MuiTableCell-root {
  padding: 6px !important;
}